
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { OutputSlide } from '@/shared/legacy/classes';
import { PitchAgency } from '@/shared/types';

import { Services } from '@/injectables/tokens';
import { OutputServiceContract } from '@/injectables';
import { Routes } from '@/router/routes';

import OutputEditToolbar from '@/components/Output/OutputEditToolbar.vue';
import OutputSidebar from '@/components/Output/OutputSidebar.vue';
import AddSlideMenu from '@/components/Output/AddSlideMenu.vue';

import OutputContainer from '@/entities/output/output-container.vue';

import ToggleSideBarButton from '@/features/output/toggle-side-bar-button.vue';
import OutputMainActionsBtn from '@/features/output/output-main-actions.vue';
import ActiveSlideContainer from '@/features/output/active-slide-container.vue';
import ColorConfiguration from '@/features/output/color-configuration.vue';

import { SlideVisibility } from '@/app/graphql';

export default Vue.extend({
  name: 'ManageTemplateSlides',

  components: {
    OutputEditToolbar,
    OutputSidebar,
    OutputContainer,
    ToggleSideBarButton,
    OutputMainActionsBtn,
    AddSlideMenu,
    ActiveSlideContainer,
    ColorConfiguration,
  },

  inject: ['$confirm'],

  useInjectable: [Services.Output],

  data: (): {
    showSidebar: boolean;
    agency: string;
    subAgency: string;
    sidebarWidth: number;
    toolbarHeight: number;
    showColorSelector: boolean;
  } => ({
    showColorSelector: false,
    showSidebar: true,
    agency: '',
    subAgency: '',
    sidebarWidth: 360,
    toolbarHeight: 64,
  }),

  computed: {
    ...mapState('output', ['activeSlideId']),
    activeSlideIndex(): number {
      const index = this.slides.findIndex((slide: OutputSlide) => slide._id === this.activeSlideId);

      return index !== -1 ? index : 0;
    },
    activeSlide(): OutputSlide | undefined {
      return this.slides[this.activeSlideIndex];
    },
    sidebarToggleStyle(): string {
      return `left: ${this.showSidebar ? this.sidebarWidth + 5 : 10}px; top: ${
        this.toolbarHeight + 11
      }px; position: fixed`;
    },
    agencyPropertyId(): string {
      return this.selectedAgency.PropertyId;
    },
    allAgencies(): PitchAgency[] {
      return this.$store.state.client.allAgencies?.list || [];
    },
    isSmallerScreen(): boolean {
      return this.$vuetify.breakpoint.width <= 1600;
    },
    outputLoading(): boolean {
      return this.$store.state.output.outputLoading;
    },
    hasUnsavedChanges(): boolean {
      return this.$store.state.output.outputHasBeenChanged;
    },
    activeSlideIsReadOnly(): boolean {
      return this.activeSlide?.readonly || false;
    },
    isChangeDisabled(): boolean {
      return false;
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAdmin'];
    },
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    productId(): string {
      const { productId = '' } = this.$route.params;
      return productId;
    },
    routeParamAgency(): string {
      const { agency = '' } = this.$route.params;
      return agency;
    },
    defaultFont(): string {
      return (this.outputService as OutputServiceContract).defaultOutputFontFamily(this.userAgency);
    },
    selectedAgency: {
      get(): PitchAgency {
        const name = this.agency || this.$store.state.output?.activeOutput?.agency || this.userAgency;
        return { PropertyId: name, name };
      },
      set(agency: PitchAgency): void {
        this.agency = agency.PropertyId;
        // side effect mustn't be in computed
        this.getAvailableLayouts({ agency: agency.PropertyId });
        this.getOutputSlides({ agency: agency.PropertyId });
      },
    },
    userAgency(): string {
      return this.$store.state.auth.user.Agency;
    },
    loading(): boolean {
      return this.$store.state.output.getOutputLoading || this.$store.state.output.outputLoading;
    },
    slides(): OutputSlide[] {
      return this.$store.getters['output/allLocalSlides'].filter(
        slide => slide?.visibility !== SlideVisibility.Deleted,
      );
    },
  },

  methods: {
    ...mapMutations('output', { updateSlide: 'UPDATE_SLIDE' }),
    onLayoutCreated({ id }: { id: string }) {
      this.getAvailableLayouts({ agency: this.userAgency });
      this.getLayoutAdditionalInfo({ layout: id });
      this.getOutputSlides({ agency: this.userAgency, layoutObj: { layout: id } });
    },
    slideIdByIndex(slideIndex: number): string {
      const foundSlide = this.slides[slideIndex];

      return foundSlide._id;
    },
    debouncedOnWheel: debounce(function (e: WheelEvent): void {
      e.preventDefault();

      let newSlideId = '';

      if (e.deltaY < 0 && this.activeSlideIndex !== 0) {
        newSlideId = this.slideIdByIndex(this.activeSlideIndex - 1);
      } else if (e.deltaY > 0 && this.activeSlideIndex !== this.slides.length - 1) {
        newSlideId = this.slideIdByIndex(this.activeSlideIndex + 1);
      }

      newSlideId && this.$store.dispatch('output/updateActiveSlide', newSlideId);
    }, 80),
    saveOutput(): void {
      const { layout } = this.$store.state.output.activeOutput;

      const savePayload = {
        agency: this.agencyPropertyId,
        layout,
        ...(this.productId && { productId: this.productId }),
      };

      this.$store.dispatch('output/saveTemplateOutput', savePayload);
    },
    async closeConfirmation() {
      return (
        await this.$confirm.show({
          title: 'Unsaved changes',
          body: 'Are you sure you want to leave without saving?',
          confirmText: 'Leave',
          cancelText: 'Stay',
        })
      ).confirmed;
    },
    async handleCloseOutputEditor(): Promise<void> {
      if (this.hasUnsavedChanges && !(await this.closeConfirmation())) {
        return;
      }
      if (this.hasUnsavedChanges) {
        this.$store.dispatch('output/clearOutputHasChanged');
      }

      if (window.history.length > 2) {
        return this.$router.go(-1);
      }
      this.$router.push({ name: Routes.Home });
    },
    setLocalOutput(): void {
      this.$store.dispatch('output/cloneOutput');
    },
    getAvailableLayouts({ agency }: { agency?: string }) {
      return this.$store.dispatch('output/getAvailableLayouts', { agency });
    },
    getLayoutAdditionalInfo(payload: { layout: string }): void {
      this.$store.dispatch('output/getLayoutAdditionalInfo', payload);
    },
    getOutputSlides({
      agency = this.agencyPropertyId,
      layoutObj,
    }: {
      agency?: string;
      layoutObj?: { layout: string };
    }): void {
      const slidesPayload = {
        agency,
        ...(layoutObj && { ...layoutObj }),
      };
      this.$store.dispatch('output/getTemplateLibrary', slidesPayload);
    },
    async updateLocalLayout(layoutObj: { layout: string }): Promise<void> {
      const canProceed = await this.checkChanges();

      if (canProceed) {
        this.showColorSelector = false;
        this.$store.dispatch('output/clearOutputHasChanged');
        this.getLayoutAdditionalInfo(layoutObj);
        this.getOutputSlides({ layoutObj });
      }
    },
    async checkChanges(): Promise<boolean> {
      let canProceed = true;

      if (this.hasUnsavedChanges) {
        canProceed = (
          await this.$confirm.show({
            title: 'Discard unsaved changes?',
            body: 'Are you sure you want to update layout without saving your changes?',
            confirmText: 'Discard',
            cancelText: 'Cancel',
          })
        ).confirmed;
      }

      return canProceed;
    },
    async resetToDefault(slide: OutputSlide) {
      const agree = (
        await this.$confirm.show({
          title: 'Reset To Default?',
          body: 'Are you sure you want to reset this slide to its original version?',
          confirmText: 'Yes',
          cancelText: 'Cancel',
        })
      ).confirmed;
      if (!agree) return;
      const outputService = this.outputService as OutputServiceContract;
      const { isErr, unwrap } = await outputService.resetDefaultSlide(slide.id);
      if (isErr()) {
        return;
      }

      const defaulted = unwrap();

      await this.updateSlide({
        ...defaulted,
        PropertyId: slide.PropertyId,
        userModified: true,
      });
      this.saveOutput();
    },
  },

  async mounted(): Promise<void> {
    const defaultLayoutsPayload: {
      agency?: string;
      productId?: string;
      layoutObj?: { layout: string };
      layoutId?: string;
    } = {
      agency: this.routeParamAgency || this.userAgency,
      ...(this.productId && { productId: this.productId }),
    };
    if (this.isAdmin) {
      this.$store.dispatch('client/getAgencies');
    }
    const data = await this.getAvailableLayouts(defaultLayoutsPayload);
    let defaultLayout = data.find(layout => layout.isDefault);

    if (!defaultLayout) {
      [defaultLayout] = data;
    }

    defaultLayoutsPayload.layoutObj = {
      layout: defaultLayout.id,
    };
    this.getLayoutAdditionalInfo({ layout: defaultLayout.id });
    this.getOutputSlides(defaultLayoutsPayload);
  },

  async beforeRouteLeave(to, from, next): Promise<void> {
    this.$store.dispatch('output/resetOutputAndProposal', {
      routeName: to.name,
    });
    next();
  },
});
